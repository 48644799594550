.cassette-container {
  perspective: 1000px;
  width: 600px;
  height: 400px;
  margin: 0 auto;
}

.cassette-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.cassette-inner.flipped {
  transform: rotateY(180deg);
}

.cassette-side {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* for Safari */
}

.cassette-front {
  z-index: 2;
}

.cassette-back {
  transform: rotateY(180deg);
}

.flip-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  font-family: 'Press Start 2P', cursive;
  background-color: #e4f155;
  color: #f324e2;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.flip-button:hover {
  background-color: #d3e044;
}

@media (max-width: 480px) {
  .cassette-container {
    width: 100%;
    max-width: auto;
    max-height: 250px;
    box-sizing: border-box;
    padding: 0;
  }

}
