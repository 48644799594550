@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

@font-face {
  font-family: 'Streamster';
  src: url('../public/fonts/Streamster.woff2') format('woff2'),
      url('../public/fonts/Streamster.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  background-color: #fb81ed;
  color: #1e2022;
  font-family: 'Press Start 2P', cursive;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;
  position: relative;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw; /* Ensure it doesn't exceed viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App-header {
  background-color: #e4f155;
  color: #f324e2;
  padding: 20px 0;
  width: 100%;
  position: relative;
  height: 120px;
  overflow: visible;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
}

.App-header h1 {
  font-family: 'Streamster';
  font-size: 5em;
  margin-top: 20px;
  text-shadow: 3px 3px 4px #bdc3c6;
}

.App-header-emoji {
  margin-left: 16px;
  font-style: italic;
  display: inline-block;
}

.App-header-logo {
  height: 120px;
  position: absolute;
  right: 20px;
  top: 20px;
}

header {
  background-color: #e4f155;
  color: #f324e2;
  padding: 20px 0;
  text-align: center;
  width: 100%;
}

h1 {
  margin: 0;
  font-weight: 600;
  font-size: 2.5rem;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  width: 100%;
  max-width: 800px; /* Reduced from 1200px to match component widths */
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}


canvas {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Chrome-specific fixes */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  body {
    width: 100vw;
    overflow-x: hidden;
  }

  .App {
    width: 100vw;
    overflow-x: hidden;
  }

  main {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

@media (max-width: 768px) and (min-width: 480px) {
  main {
    padding: 10px;
  }

  header {
    padding: 8px 0;
  }

  h1 {
    margin-top: 40px;
    font-size: 2rem;
    text-shadow: none;
  }

  .App-header {
    height: 120px;
  }
  
  .App-header-logo {
    visibility: hidden;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  header {
    padding: 4px 0;
  }

  .App-header {
    height: 40px;
    padding-bottom: 24px;
  }
  
  .App-header-logo {
    visibility: hidden;
  }

  .App-header h1 {
    font-family: 'Streamster', 'Press Start 2P', cursive;
    font-size: 2.7em;
    text-shadow: none;
  }
}

.current-note {
  background-color: #e4f155;
  color: #f324e2;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
}

.current-note h2 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.current-note p {
  font-size: 1.5rem;
  margin: 0;
}

.current-note h3 {
  font-size: 0.9rem;
  margin-top: 15px;
  margin-bottom: 5px;
}

.current-note p {
  font-size: 0.8rem;
  margin: 5px 0;
  word-wrap: break-word;
}

.header-line-break {
  margin-top: 0px;
  margin-bottom: 10px;
  height: 3px;
  width: 100%;
  background-color: aqua;
}