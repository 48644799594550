@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

:root {
  --crt-bg-color: #000000;
  --crt-text-color: #00ff00;
  --crt-hash-color: #00ff00;
  --crt-link-color: #ffff00;
  --crt-highlight-color: #ff00ff;
  --note-section-bg: #000080;
  --note-section-text: #eeff00;
  --note-section-header: #ff00ff;
  --crt-orange: #FFA500;
  font-variant-ligatures: none;
}

/* CRT Container */
.crt {
  width: 100%;
  max-width: 800px;
  min-height: 680px;
  border-radius: 0px;
  aspect-ratio: 4 / 3;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: var(--crt-bg-color);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.6), inset 0 0 10px rgba(0, 255, 0, 0.4);
  border: 32px solid #0df4f4;
}

/* CRT Screen Effect */
.crt::before,
.crt::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;
}

.crt::before {
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), 
              linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  background-size: 100% 2px, 3px 100%;
  animation: flicker 0.15s infinite;
}

.crt::after {
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.9) 100%);
}

/* Terminal */

.terminal {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  color: var(--crt-text-color);
  font-family: 'VT323', monospace;
  font-size: 1.2em;
  line-height: 1.5;
  text-shadow: 0 0 5px var(--crt-text-color);
  box-sizing: border-box;
}

/* Text and Links */
.line {
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  margin-bottom: 5px;
  animation: typing 1s steps(30, end) forwards, blink 0.5s step-end infinite alternate;
}

.crt-title {
  color: var(--crt-link-color);
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 1.8em;
  animation: glow 1.5s ease-in-out infinite alternate;
}

.crt-link,
.copyable-hash {
  color: var(--crt-link-color);
  text-decoration: none;
  transition: all 0.3s ease;
}

.crt-link:hover,
.copyable-hash:hover {
  color: var(--crt-highlight-color);
  text-shadow: 0 0 10px var(--crt-highlight-color);
}

/* Hash Display */
.hash-line {
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
}

.hash-label {
  margin-bottom: 2px;
  color: var(--crt-hash-color);
}

.hash-container {
  display: flex;
  align-items: center;
  word-break: break-all;
}

.copy-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 0.8em;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.copy-icon:hover {
  opacity: 1;
}

/* Current Note Section */
.current-note-section {
  margin-top: 10px;
  padding: 10px;
  background-color: var(--note-section-bg);
  border: 2px solid var(--crt-link-color);
  box-shadow: 0 0 10px var(--crt-link-color);
  font-family: 'VT323', monospace;
  font-size: 1em;
  line-height: 1.2;
}

.current-note-section h3 {
  color: var(--note-section-header);
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 1.4em;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
}

.current-note-section p {
  margin: 5px 0;
  color: var(--note-section-text);
}

.highlight-char {
  background-color: var(--crt-highlight-color);
  color: var(--crt-bg-color);
  padding: 0 2px;
}

/* Animations */
@keyframes typing {
  from { width: 0; opacity: 1; }
  to { width: 100%; opacity: 1; }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes flicker {
  0% { opacity: 0.27861; }
  5% { opacity: 0.34769; }
  10% { opacity: 0.23604; }
  15% { opacity: 0.90626; }
  20% { opacity: 0.18128; }
  25% { opacity: 0.83891; }
  30% { opacity: 0.65583; }
  35% { opacity: 0.67807; }
  40% { opacity: 0.26559; }
  45% { opacity: 0.84693; }
  50% { opacity: 0.96019; }
  55% { opacity: 0.08594; }
  60% { opacity: 0.20313; }
  65% { opacity: 0.71988; }
  70% { opacity: 0.53455; }
  75% { opacity: 0.37288; }
  80% { opacity: 0.71428; }
  85% { opacity: 0.70419; }
  90% { opacity: 0.7003; }
  95% { opacity: 0.36108; }
  100% { opacity: 0.24387; }
}

@keyframes glow {
  from { text-shadow: 0 0 5px var(--crt-link-color); }
  to { text-shadow: 0 0 10px var(--crt-link-color), 0 0 20px var(--crt-link-color); }
}

/* Media Queries */
@media (max-width: 768px) {
  .crt {
    width: 600px;
    min-height: 590px;
    padding: 8px;
  }


  .terminal .cursor {
    width: 8px;
    height: 12px;
    bottom: 10px;
  }

  .terminal .block-record-section {
    font-size: 0.9em;
    padding: 8px;
  }
  .crt-title {
    font-size: 1.4em;
  }
  .current-note-section {
    font-size: 0.8em;
  }
  .terminal .ascii-visualizer {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .crt {
    min-height: 470px;
    width: 360px;
    padding: 4px;
    border: 10px solid #0df4f4;
  }

  .terminal {
    min-height: 790px;
  }

  .terminal .cursor {
    width: 4px;
    height: 6px;
    bottom: 10px;
  }

  .terminal, .block-record-section {
    font-size: 0.7em;
    padding: 6px;
  }
  .crt-title {
    font-size: 1.8em;
  }
  .current-note-section {
    font-size: 0.9em;
  }
  .current-note-section div h3 {
    font-size: 1.9em;
  }

  .current-note-section div .puzzle-hash {
    font-size: 1.2em;
  }

  .terminal .ascii-visualizer {
    font-size: 14px;
  }
}

/* Add this new style for the block record section */
.block-record-section {
  margin-top: 20px;
  padding: 10px;
  background-color: var(--crt-bg-color);
  border: 2px solid var(--crt-text-color);
  box-shadow: 0 0 10px var(--crt-text-color);
  font-family: 'VT323', monospace;
  font-size: 1em;
  line-height: 1.2;
}

.speed-display {
  position: relative;
  bottom: 10px;
  left: 0px;
  font-family: 'VT323', monospace;
  font-size: 1em;
  color: var(--crt-text-color);
  text-shadow: 0 0 5px var(--crt-text-color);
}

.cursor {
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 10px;
  height: 20px;
  background-color: var(--crt-text-color);
  animation: blink 1s step-end infinite;
}

.oscilloscope {
  width: 100%;
  height: 100px;
  background-color: #000;
  border: 2px solid var(--crt-text-color);
  margin-top: 20px;
}

.oscilloscope-canvas {
  width: 100%;
  height: 100%;
}

.waveform {
  width: 100%;
  height: 100px;
  background-color: #000;
  border: 2px solid var(--crt-text-color);
  margin-top: 20px;
}

.waveform-canvas {
  width: 100%;
  height: 100%;
}

.particle-system {
  width: 100%;
  height: 100px;
  background-color: #000;
  border: 2px solid var(--crt-text-color);
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.particle-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ascii-visualizer {
  font-family: monospace;
  line-height: 1;
  color: var(--crt-orange);
  background-color: var(--crt-bg-color);
  padding: 10px;
  border: 1px solid var(--crt-orange);
  margin-top: 20px;
  white-space: pre;
  overflow: hidden;
  text-align: center;
  text-shadow: 0 0 5px var(--crt-orange);
  font-size: 32px;
}
