.powered-by-footer {
  text-align: center;
  padding: 1.5rem 0;
  font-family: 'VT323', monospace;
  font-size: 1.8rem;
  letter-spacing: 0.02em;
}

.powered-by-footer p {
  margin: 0;
  color: #e4f155;
  text-shadow: 0 0 15px rgba(228, 241, 85, 0.7);
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 2px rgba(228, 241, 85, 0.7),
                 0 0 9px rgba(228, 241, 85, 0.7);
  }
  to {
    text-shadow: 0 0 6px rgba(228, 241, 85, 0.9),
                 0 0 16px rgba(228, 241, 85, 0.9);
  }
}

.powered-by-footer a {
  color: #380437;
  font-weight: 500;
  text-decoration: none;
  padding: 2px 8px;
  border-radius: 3px;
  transition: all 0.2s ease;
  position: relative;
}

.powered-by-footer a:hover {
  color: #f324e2;
  background-color: #e4f155;
}

.powered-by-footer a::after {
  display: none;
}

@media (max-width: 480px) {
  .powered-by-footer {
    font-size: 1.2rem;
    text-align: center;
  }
}

.social-link {
  display: inline-flex;
  align-items: center;
  margin-top: 8px;
  font-size: 0.9em;
  color: #000000;
  text-decoration: none;
  transition: color 0.2s ease;
}

.social-link:hover {
  color: #f324e2;
  background-color: transparent;
}

.social-link .x-logo {
  margin-right: 5px;
  fill: currentColor;
}

.social-link span {
  display: inline-block;
}