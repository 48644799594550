.controls-container {
  width: 400px;
  height: 60px;
  margin: 20px auto;
  background: linear-gradient(to bottom, #e0e0e0, #f5f5f5);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
  box-sizing: border-box;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
}

.control-button {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background: linear-gradient(to bottom, #ffffff, #e0e0e0);
  border: 1px solid #ccc;
  border-right: none;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #333;
  padding: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.control-button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.control-button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 1px solid #ccc;
}

.control-button:hover:not(:disabled) {
  background: linear-gradient(to bottom, #f0f0f0, #d0d0d0);
}

.control-button:active:not(:disabled) {
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.control-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Make the play button slightly longer */
.play {
  flex: 1.6;
}

.button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding-left: 4px;
}

.button-icon {
  font-size: 16px;
  line-height: 1;
}

.button-text {
  font-size: 8px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}

/* Media Queries */
@media (max-width: 768px) {
  
}

@media (max-width: 480px) {
  .controls-container {
    width: 330px;
    height: 60px;
    margin: 20px auto;
    background: linear-gradient(to bottom, #e0e0e0, #f5f5f5);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 5px;
    box-sizing: border-box;
  }

  /* Make the play button slightly longer */
  .play {
    flex: 2.2;
  }

  .button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    padding-left: 1px;
  }

  .button-icon {
    font-size: 18px;
    line-height: 1;
  }

  .controls button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .button-text {
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
  }
}